import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
import  TwoView  from "@/views/twoView.vue";
const routes = [
 
  {
    path: '/',
    name: 'home',
    // redirect: '/',
    component: () => import('@/views/HomeView.vue'),
   
  },
  {
    path: '/productCenter',
    name: 'productCenter',
    component: TwoView
  },
 


]

const router = new VueRouter({
  mode: 'history',
  routes
})


export default router
