<template>
    <div class="eleDistrubTotal">
        <header class="header">
      <div class="left">
        <img class="leftImg1" @click="$router.push('/');" src="../../public/images/LOGO.png" alt="">
        <img class="leftImg2" src="../../public/images/headTitle.png" alt="">
      </div>
      <div class="right">
        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
          <el-menu-item index="1">首页</el-menu-item>
          <el-menu-item index="2">产品中心</el-menu-item>
          <el-menu-item index="3">行业应用</el-menu-item>
          <el-menu-item index="4">关于容远</el-menu-item>
          <el-menu-item index="5">联系我们</el-menu-item>
        </el-menu>
      </div>
       </header>

        <main class="main">
           <div class="carousel">
                <el-carousel indicator-position="outside" class="carouselImg" :autoplay="false" ref="myCarousel" :active-index="centerCurrentIndex">
                    <el-carousel-item v-for="(item, index) in images" :key="index" class="carouselitem" >
                        <img class="img" :src="item" alt="轮播图片">
                    </el-carousel-item>
                </el-carousel>
            </div> 
            <div class="bodys">

                <div>
                    <img class="bodyImg"  src="../../public/images/home_title_product center.png" alt="">
                    <div>
                     
                <div class="centerTitle">
                        <div 
                            v-for="(item, index) in productCenter" 
                            :key="index" 
                            :style="{ 
                                background: item.isActive ? '#0467BF' : '#ebebed', 
                                color: item.isActive ? 'white' : 'black', 
                                width: '245px', 
                                lineHeight: '68px', 
                                textAlign: 'center', 
                                borderRadius: '12px' 
                            }" 
                            @click="setActive(index)"
                        >
                            {{ item.title }}
                        </div>
                </div>
    
                <div>
                   <div class="centerImgVideo">
                     <img
                        v-show="centerCurrentIndex !== null &&centerCurrentIndex!=3" 
                        :src="productCenter[centerCurrentIndex]?.Src" alt="" 
                     />
                     <!-- <video class="video" controls v-show="centerCurrentIndex !== null &&centerCurrentIndex===0">
                         <source :src="productCenter[centerCurrentIndex]?.Src" type="video/mp4">
                     </video> -->
                     <div class="controlPart" v-show="centerCurrentIndex !== null &&centerCurrentIndex===3" >
                       <div class="controlPartInner">
                        <img :src="productCenter[centerCurrentIndex]?.Src1" alt="">
                       </div>
                       <div class="controlPartInner">
                        <img :src="productCenter[centerCurrentIndex]?.Src2" alt="">
                       </div>
                     </div>
                   </div>
                    <div v-show="centerCurrentIndex !== null&&centerCurrentIndex!=3" class="centerMessage">
                        {{ productCenter[centerCurrentIndex]?.productMessage }}
                    </div>
                    <div v-show="centerCurrentIndex !== null&&centerCurrentIndex===3" class="centerMessage">
                        <p class="Controlmessage">
                            {{ productCenter[centerCurrentIndex]?.productMessage1 }}
                        </p> 
                         <p class="Controlmessage">
                            {{ productCenter[centerCurrentIndex]?.productMessage2 }}
                         </p> 
                         <p class="Controlmessage">
                            {{ productCenter[centerCurrentIndex]?.productMessage3 }}
                         </p> 
                    </div>
                    </div>
                    </div>
                  
                </div>

            </div>
        </main>
        
      <footer>
      <div class="footer">
        <div class="footerPart" >
        <div class="footleft">
          <div class="leftertop">
            <ul>
              <li s>产品中心</li>
              <li>无人机探测与反制产品</li>
              <li>智能管控产品</li>
              <li>智能识别及处理产品</li>
              <li>加固信息产品</li>
            </ul>
            <ul>
              <li>行业应用</li>
              <li>单兵训练</li>
              <li>海上防导</li>
              <li>智慧哨塔</li>
              <li>边境巡逻</li>
            </ul>
            <ul>
              <li>联系我们</li>
              <li>联系我们</li>
              <li>商务合作</li>
            </ul>
            <ul>
              <li>关于容远</li>
              <li>公司简介</li>
              <li>荣誉资质</li>
              <li>发展历程</li>
            </ul>
          </div>
          <div class="footleftbottom">
            © 成都国科容远科技有限公司版权所有 <a style="color: white; text-decoration: none;" href="https://beian.miit.gov.cn/">蜀ICP备2024107035号-1</a> 
          </div>
          <!-- <span>© 成都国科容远科技有限公司版权所有 蜀ICP备111111114号-1 技术支持 公安备案号：川公网安备12345678998</span> -->
        </div>
        <div class="footright">
         <div class="footNumber">
           <div class="footNumberspan1">
            <span>电话</span> <span class="citymargin">成都</span> <span>028-86248068</span>
          </div>
          <div class="footNumberspan2">
            <span class="citymargin">北京</span> <span>010-84566771</span>
          </div>
         </div>
          <div class="footCode">
            <img class="QRcode1" src="../../public/images/qrCode.png" alt="">
            <img class="QRcode2" src="../../public/images/qrCode.png" alt="">
            <!-- <router-link to="/twoView">Go to Two View</router-link> -->
          </div>
        </div>
        </div>

      </div>
    </footer>
    </div>
</template>

<script>
    export default {
        // name: 'HelloWorld',
        props: {
          
        },
        data() {
            return {
                centerCurrentIndex:0,
                activeIndex: '1',
                images: [
                    require('../../public/images/banner_bianxie.png'),
                    require('../../public/images/banner_beifu.png'),
                    require('../../public/images/banner_pika.png'),
                    require('../../public/images/banner_control.png'),
                ],
                aboutRongYuan: [
                    {
                        src: require('../../public/images/home_icon_aboutus1.png'),
                        title: '诚信·尊重'
                    },
                    {
                        src: require('../../public/images/home_icon_aboutus2.png'),
                        title: '责任·担当'
                    },
                    {
                        src: require('../../public/images/home_icon_aboutus3.png'),
                        title: '创新·开放'
                    },
                    {
                        src: require('../../public/images/home_icon_aboutus4.png'),
                        title: '自信·乐观'
                    },

                ],
                form: {
                    name: '',

                    resource: '',

                },
                productCenter: [{
                    title: '便携',
                    isActive: true,
                    productMessage: '便携式无人机探测与反制系统是一款智能化、小型化、模块化的反无人机装备，采用雷达、光学、无线电信号三种融合侦察手段，可对防御区域内的低空无人机目标实现快速搜索、高效识别、自动捕获，利用信号压制、导航干扰等手段，对无人机目标的控制链路、图传链路进行侦察、干扰，使无人机在进入受控范围后失去控制，有效迫降或返航。',
                    Src:require('../../public/images/portable.png'),
                },
                {
                    title:'背负',
                    isActive: false,
                    productMessage:'背负式无人机探测与反制系统采用探测与反制一体化设计，是集成度高、方便携带、锂电池内置的单人背负式无人机探测与反制设备。手持终端不但可以实时显示背负设备的电磁信号探测结果，还可快速开启无人机导航和图传信号压制功能，能够在行进中实时进行探测与反制作业。',
                    Src:require('../../public/images/tbackpack.png'),
                    },
                {
                    title:'皮卡',
                    isActive: false,
                    productMessage:'轻型机动版无人机侦察与反制车，是一款针对通用皮卡底盘（我司选用长城炮柴油版）开发的多手段、高集成、可机动部署的一整套反无人机装备体系，集成便携探测与反制设备、背负反制设备、背负FPV反制设备构成三种协同部署低空防御体系，可对防御区域内的低空无人机目标实现快速搜索、高效识别，并在车内或远程操作人员的确定下对目标进行电磁干扰压制、导航诱骗夺控、FPV拦截三种组合防御。',
                     Src: require('../../public/images/tpick.png'),
                    },
                {
                    title:'智能管控产品',
                    isActive: false,
                    productMessage1: 'JKFT2系列是国产化平台系列产品，是一款无扇低功耗嵌入式19英寸整机，板载FT2000/4或D2000/8国产飞腾处理器，整机支持国产银河麒麟V10/V4、Linux、Win10等操作系统。整机结构加固，外形尺寸美观，机壳采用铝合金机加成形。结构坚固、无风扇设计，外壳增加纹路有利散热，具有忧良的密封防尘、散热与抗振性能，广泛应用于车载多设备载荷接入智能控制领域，实现设备一键化控制和状态监控。',
                    productMessage2: 'JKRK2系列是国产化平台系列产品，是一款无扇低功耗嵌入式19英寸整机，板载RK3588国产ARM处理器，整机支持Linux操作系统。',
                    productMessage3: ' 整机结构加固，外形尺寸美观，机壳采用铝合金机加成形。结构坚固、无风扇设计，外壳增加纹路有利散热，具有忧良的密封防尘、散热与抗振性能，可广泛应用于车载多设备载荷接入智能控制领域，实现设备一键化控制和状态监控。',
                    Src1: require('../../public/images/SWissinmicro.png'),
                    Src2: require('../../public/images/soar.png'),
                },
                ],
                videoSrc: '',
            }
        },
        methods: {
            handleSelect(key,) {
                switch (key) {
                    case 1:

                        break;
                    case 2:

                        break;
                    case 3:

                        break;
                    case 4:

                        break;
                    case 5:

                        break;

                    default:
                        break;
                }
            },
            setActive(index) {
             const carousel = this.$refs.myCarousel;
             this.productCenter.forEach((item, i) => {
            item.isActive = (i === index);
        });
                this.centerCurrentIndex = index; // 更新当前索引
        carousel.setActiveItem(index)
    },
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .eleDistrubTotal {
         display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
        /* max-width: 1920px; */
        width: 100%;
        padding: 0;
        margin: 0;

         .header{
            max-height:69px;
            width: 62.5%;
            /* margin: 0 auto; */
            display: flex;
            justify-content: space-between;
            align-content: center;
            .left{
            max-width: 35%;
            
            display: flex;
            justify-content: space-between;
            align-content: center;
            .leftImg1{
                max-width: 100%;
                height: auto;
                margin-right: 4%;
            }
            .leftImg2{
                max-width: 100%;
                height: auto;
                margin: auto;
            }
            }
            .right{
            width: 46%;
            }
        }     

    .main{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    .carousel{
    width: 1920px;
    height: 450px;
    overflow: hidden;
       :deep() .el-carousel__indicators--outside{
      position: absolute;
        top: 91%;
    }
    :deep() .el-carousel__indicators--outside button{
      height: 5px;
      border-radius: 3px;
     }
                :deep() .el-carousel__arrow--left{
                  top: 70%;
                  left: 296px;
                  color:#ebebed;
                  background-color: rgba(255, 255, 255, .4);
                  width: 60px;
                  height: 60px;
                }
                :deep() .el-icon-arrow-left,
            :deep() .el-icon-arrow-right {
              font-size: 40px !important; /* 根据需要调整大小 */
              color: #fff; /* 可选：更改颜色 */
           }
    :deep() .el-carousel__arrow--right {
          top: 70%;
          right: 250px;
            color:#ebebed;
             background-color: rgba(255, 255, 255, .4);
             width: 60px;
            height: 60px;
             }
     .carouselImg{
       height: 450px; 
       position: relative;
       .carouselitem {
        width: 100%; 
        height: 450px;
        .img{
          object-fit: cover; /* 保持图片比例并填充容器 */
        }
       }
             }
  }
 .bodys{
    padding: 0;
    margin: 0;
    max-width: 1200px;
    width: 100%;
    height: auto;
    .el-carousel__item h3 {
                color: #475669;
                font-size: 18px;
                opacity: 0.75;
                line-height: 300px;
                margin: 0;
            }

            .el-carousel__item:nth-child(2n) {
                background-color: #99a9bf;
            }

            .el-carousel__item:nth-child(2n+1) {
                background-color: #d3dce6;
            }
            .bodyImg{
                margin: 80px 0 ;
                 width: 319px; 
                 height: 49px;
            }
            .centerTitle{
                display: flex;
                justify-content: space-between;
                align-content: space-between;
                margin-bottom: 55px;
            }
           .centerMessage{
            width: 1200px; 
            font-size: 22px; 
            text-align: left;
            color: #333333;
            line-height: 44px;
            text-indent: 2em; 
            margin-top: 40px; 
            margin-bottom: 270px;
            .Controlmessage{
                margin: 0;
            }
           }
           .centerImgVideo{
            width: 1198px;
            height: 471px;
            background: #f7f7f7;
            display: flex;
            justify-content: center;
            align-items: center;
            .video{
              width: 1198px;
            height: 471px;

            }
            .controlPart{
                width: 100%;
                display: flex;
                background: #fff;
                justify-content: space-between;
                align-items: center;
                .controlPartInner{
                    width: 585px;
                    height: 471px;
                    background: #f7f7f7;
                    border-radius: 12px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            
           }
    
  }
  }

   .footer{
    width:1920px;
    height: 472px;
    margin: 0 auto;
    background-color: #656569;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding:112px 390px 106px 368px; */
      box-sizing: border-box;
      .footerPart{
        width: 62%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .footleft{
          display: flex; 
          align-items: flex-start;
          flex-direction: column; 
          color: #FFFFFF;
          font-size: 14px;
           .leftertop{
         display: flex;
        justify-content: space-between;
        width: 623px;
        text-align: left;
        line-height: 40px;
          letter-spacing: 1px;
          color: #FFFFFF;
        font-size: 14px;
          margin-right: 100px;
          margin-bottom: 60px;
         ul {
           list-style: none;
           /* 去掉默认的列表样式 */
           padding: 0;
           /* 去掉内边距 */
           margin: 0;
           /* 去掉外边距 */
         }
    
          li {
          list-style-type:  none; /* 默认隐藏所有 <li> */
        }

        li:first-child {
            list-style-type: disc; /* 显示每个 <ul> 的第一个 <li> 并保留项目符号 */
      margin-bottom: 20px;
            }

        /* 设置第一个 li 的标记颜色为红色 */
        li:first-child::marker {
            color: #F5A300; /* 将标记颜色设置为红色 */
        }
    }
        }
        .footright{
          width: 230px;
          height: 200px;
          display: flex;
          justify-content: space-between;
          align-content: space-between;
          flex-direction: column;
          .footNumber{
            /* margin-top: 20px; */
            font-size: 12px;
            color: #FFFFFF;
            .citymargin{
              margin: 10px 20px;
            }
            .footNumberspan1{
              display: flex;
              justify-content:flex-end;
              align-items:center;
              flex-direction: row;
            }
            .footNumberspan2{
              display: flex;
              justify-content:flex-end;
              align-items:center;
              flex-direction: row;
            }
          }
          .footCode{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .QRcode1{
              width: 105px;
              height: 105px;
            }
            .QRcode2{
              width: 105px;
              height: 105px;
            }
          }
        }
      }
   
         
  }
    }
</style>