var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"eleDistrubTotal"},[_c('header',{staticClass:"header"},[_c('div',{staticClass:"left"},[_c('img',{staticClass:"leftImg1",attrs:{"src":require("../../public/images/LOGO.png"),"alt":""},on:{"click":function($event){return _vm.$router.push('/');}}}),_c('img',{staticClass:"leftImg2",attrs:{"src":require("../../public/images/headTitle.png"),"alt":""}})]),_c('div',{staticClass:"right"},[_c('el-menu',{staticClass:"el-menu-demo",attrs:{"default-active":_vm.activeIndex,"mode":"horizontal"},on:{"select":_vm.handleSelect}},[_c('el-menu-item',{attrs:{"index":"1"}},[_vm._v("首页")]),_c('el-menu-item',{attrs:{"index":"2"}},[_vm._v("产品中心")]),_c('el-menu-item',{attrs:{"index":"3"}},[_vm._v("行业应用")]),_c('el-menu-item',{attrs:{"index":"4"}},[_vm._v("关于容远")]),_c('el-menu-item',{attrs:{"index":"5"}},[_vm._v("联系我们")])],1)],1)]),_c('main',{staticClass:"main"},[_c('div',{staticClass:"carousel"},[_c('el-carousel',{ref:"myCarousel",staticClass:"carouselImg",attrs:{"indicator-position":"outside","autoplay":false,"active-index":_vm.centerCurrentIndex}},_vm._l((_vm.images),function(item,index){return _c('el-carousel-item',{key:index,staticClass:"carouselitem"},[_c('img',{staticClass:"img",attrs:{"src":item,"alt":"轮播图片"}})])}),1)],1),_c('div',{staticClass:"bodys"},[_c('div',[_c('img',{staticClass:"bodyImg",attrs:{"src":require("../../public/images/home_title_product center.png"),"alt":""}}),_c('div',[_c('div',{staticClass:"centerTitle"},_vm._l((_vm.productCenter),function(item,index){return _c('div',{key:index,style:({ 
                            background: item.isActive ? '#0467BF' : '#ebebed', 
                            color: item.isActive ? 'white' : 'black', 
                            width: '245px', 
                            lineHeight: '68px', 
                            textAlign: 'center', 
                            borderRadius: '12px' 
                        }),on:{"click":function($event){return _vm.setActive(index)}}},[_vm._v(" "+_vm._s(item.title)+" ")])}),0),_c('div',[_c('div',{staticClass:"centerImgVideo"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.centerCurrentIndex !== null &&_vm.centerCurrentIndex!=3),expression:"centerCurrentIndex !== null &&centerCurrentIndex!=3"}],attrs:{"src":_vm.productCenter[_vm.centerCurrentIndex]?.Src,"alt":""}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.centerCurrentIndex !== null &&_vm.centerCurrentIndex===3),expression:"centerCurrentIndex !== null &&centerCurrentIndex===3"}],staticClass:"controlPart"},[_c('div',{staticClass:"controlPartInner"},[_c('img',{attrs:{"src":_vm.productCenter[_vm.centerCurrentIndex]?.Src1,"alt":""}})]),_c('div',{staticClass:"controlPartInner"},[_c('img',{attrs:{"src":_vm.productCenter[_vm.centerCurrentIndex]?.Src2,"alt":""}})])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.centerCurrentIndex !== null&&_vm.centerCurrentIndex!=3),expression:"centerCurrentIndex !== null&&centerCurrentIndex!=3"}],staticClass:"centerMessage"},[_vm._v(" "+_vm._s(_vm.productCenter[_vm.centerCurrentIndex]?.productMessage)+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.centerCurrentIndex !== null&&_vm.centerCurrentIndex===3),expression:"centerCurrentIndex !== null&&centerCurrentIndex===3"}],staticClass:"centerMessage"},[_c('p',{staticClass:"Controlmessage"},[_vm._v(" "+_vm._s(_vm.productCenter[_vm.centerCurrentIndex]?.productMessage1)+" ")]),_c('p',{staticClass:"Controlmessage"},[_vm._v(" "+_vm._s(_vm.productCenter[_vm.centerCurrentIndex]?.productMessage2)+" ")]),_c('p',{staticClass:"Controlmessage"},[_vm._v(" "+_vm._s(_vm.productCenter[_vm.centerCurrentIndex]?.productMessage3)+" ")])])])])])])]),_vm._m(0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('footer',[_c('div',{staticClass:"footer"},[_c('div',{staticClass:"footerPart"},[_c('div',{staticClass:"footleft"},[_c('div',{staticClass:"leftertop"},[_c('ul',[_c('li',{attrs:{"s":""}},[_vm._v("产品中心")]),_c('li',[_vm._v("无人机探测与反制产品")]),_c('li',[_vm._v("智能管控产品")]),_c('li',[_vm._v("智能识别及处理产品")]),_c('li',[_vm._v("加固信息产品")])]),_c('ul',[_c('li',[_vm._v("行业应用")]),_c('li',[_vm._v("单兵训练")]),_c('li',[_vm._v("海上防导")]),_c('li',[_vm._v("智慧哨塔")]),_c('li',[_vm._v("边境巡逻")])]),_c('ul',[_c('li',[_vm._v("联系我们")]),_c('li',[_vm._v("联系我们")]),_c('li',[_vm._v("商务合作")])]),_c('ul',[_c('li',[_vm._v("关于容远")]),_c('li',[_vm._v("公司简介")]),_c('li',[_vm._v("荣誉资质")]),_c('li',[_vm._v("发展历程")])])]),_c('div',{staticClass:"footleftbottom"},[_vm._v(" © 成都国科容远科技有限公司版权所有 "),_c('a',{staticStyle:{"color":"white","text-decoration":"none"},attrs:{"href":"https://beian.miit.gov.cn/"}},[_vm._v("蜀ICP备2024107035号-1")])])]),_c('div',{staticClass:"footright"},[_c('div',{staticClass:"footNumber"},[_c('div',{staticClass:"footNumberspan1"},[_c('span',[_vm._v("电话")]),_vm._v(" "),_c('span',{staticClass:"citymargin"},[_vm._v("成都")]),_vm._v(" "),_c('span',[_vm._v("028-86248068")])]),_c('div',{staticClass:"footNumberspan2"},[_c('span',{staticClass:"citymargin"},[_vm._v("北京")]),_vm._v(" "),_c('span',[_vm._v("010-84566771")])])]),_c('div',{staticClass:"footCode"},[_c('img',{staticClass:"QRcode1",attrs:{"src":require("../../public/images/qrCode.png"),"alt":""}}),_c('img',{staticClass:"QRcode2",attrs:{"src":require("../../public/images/qrCode.png"),"alt":""}})])])])])])
}]

export { render, staticRenderFns }